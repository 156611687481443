import React from 'react';
import './Loader.css';

function Loader()
{
    return (
        <div className="loader">
            Catching 'em all...
        </div>
    )
}

export default Loader;